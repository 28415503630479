<template>
    <v-dialog
        v-model="visible"
        :persistent="persistent"
        :max-width="maxWidth"
        @keydown:esc="close"
    >
        <v-card :loading="loading" :disabled="loading">
          <v-row v-if="styledHeader && title" dense no-gutters class="shrink">
            <v-theme-provider dark>
              <v-toolbar dark :color="(!errorHeader ? 'breeze_blue' : 'error_header')" flat>
                <v-toolbar-title><v-icon>mdi-phone-hangup</v-icon> {{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="close"
                    :disabled="loading"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
            </v-theme-provider>
          </v-row>
            <v-card-title v-if="!styledHeader && title">{{ title }}</v-card-title>
            <v-card-text :class="styledHeader? 'pt-6' : ''">
                <slot />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="visible=false"
                    v-html="okOnly ? 'OK' : noText"
                    :disabled="loading"
                />
                <v-btn
                    v-if="!okOnly"
                    :color="yesColor"
                    text
                    @click="$emit('click')"
                    :loading="loading"
                    :disabled="yesDisabled"
                    >{{ yesText }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';

export default {
    name: 'SimpleDialog',
    mixins: [dialogMixin],
    methods: {
        close() {
            this.visible = false;
        },

    },
    props: {
        title: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: Number,
            default: 500,
        },
        yesColor: {
            type: String,
            default: 'primary',
        },
        okOnly: {
            type: Boolean,
            default: false,
        },
        noText: {
            type: String,
            default: 'No',
        },
        yesText: {
            type: String,
            default: 'Yes',
        },
        yesDisabled: {
            type: Boolean,
            default: false,
        },
      /**
       * This will place a header section at the time. Making the style match the complex dialogs component.
       */
        styledHeader:{
          type: Boolean,
          default: false,
        },
      /**
       * This will style the header in red. Useful for delete confirm dialogs.
       */
        errorHeader:{
          type: Boolean,
          default: false
        },
      /**
       * Places an icon in the error header
       */
        errorHeaderIcon:{
          type: String,
          default:null
        }

    },
};
</script>

<style scoped>
    .v-card__text, .v-card__title {
        word-break: normal !important; /* maybe !important  */
    }
</style>